import { autoserializeAs, autoserializeAsArray } from 'dcerialize';

export class CustomerDomainList {
  /**
   * Customer name and domain list
   */
  @autoserializeAsArray(() => CustomerDomainResponse) items: CustomerDomainResponse[];

  /**
   * Total of customers
   */
  @autoserializeAs(() => Number) total: number;

  constructor(items: CustomerDomainResponse[], total: number) {
    this.items = items;
    this.total = total;
  }
}

export class CustomerDomainResponse {
  /**
   * Customer id
   */
  @autoserializeAs(() => Number) id: number;

  /**
   * Customer name
   */
  @autoserializeAs(() => String) name: string;

  /**
   * Customer domain
   */
  @autoserializeAs(() => String) domain: string;

  constructor(id: number, name: string, domain: string) {
    this.id = id;
    this.name = name;
    this.domain = domain;
  }
}

export class CallbellCustomerConfig {
  /**
   * Callbell Token
   */
  @autoserializeAs(() => String) token: string;

  /**
   * Callbell UUID
   */
  @autoserializeAs(() => String) uuid: string;

  constructor(token: string, uuid: string) {
    this.token = token;
    this.uuid = uuid;
  }
}

export class CustomerConfig {
  /**
   * Customer domain
   */
  @autoserializeAs(() => String) domain: string;

  /**
   * Customer name
   */
  @autoserializeAs(() => String) name?: string;

  /**
   * Customer logoUrlBase
   */
  @autoserializeAs(() => String) logoUrlBase?: string;

  /**
   * Customer callbellToken
   */
  @autoserializeAs(() => CallbellCustomerConfig) callbellConfig?: CallbellCustomerConfig;

  constructor(domain: string, name?: string, logoUrlBase?: string, callbellConfig?: CallbellCustomerConfig) {
    this.domain = domain;
    this.name = name;
    this.logoUrlBase = logoUrlBase;
    this.callbellConfig = callbellConfig;
  }
}
