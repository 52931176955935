import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Deserialize, IJsonObject } from 'dcerialize';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CustomerConfig, CustomerDomainList } from '../models/customer';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  private http = inject(HttpClient);
  private apiService = inject(ApiService);

  path = '/customers';

  constructor() {
    this.path = this.apiService.getApiUrl() + this.path;
  }

  /**
   * Get customers given a line
   */
  getCustomersWithLine(): Observable<CustomerDomainList> {
    const educaUniversityPath = this.apiService.getEducaUniversityApiUrl() + '/customer';

    return this.http.get<IJsonObject>(`${educaUniversityPath}/with-line/university`).pipe(
      map((jsonRequest) => {
        return Deserialize(jsonRequest, () => CustomerDomainList);
      })
    );
  }

  /**
   * Get current customer config
   */
  getCustomerConfig(): Observable<CustomerConfig> {
    return this.http.get<IJsonObject>(`${this.path}/${this.apiService.getApiHostname()}`).pipe(
      map((jsonRequest) => {
        return Deserialize(jsonRequest, () => CustomerConfig);
      })
    );
  }
}
